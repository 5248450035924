:root {
 --text-primary: #010102;
 --text-tertiary: #5d6672;
 --text-w-100: #fff;
 --text-accent: #0166f8;
 --borders-primary: #ced2d7;
 --backgrounds-light: #ebedf2;
}

@font-face {
 font-family: "Grtsk Tera";
 src: url("fonts/GrtskTera-Semibold.ttf") format("woff2");
}

body {
 margin: 0;
 padding: 0;
 background: var(--text-w-100);
 font-family: "Manrope", sans-serif;
 font-size: 16px;
 font-style: normal;
 font-weight: 500;
 line-height: 24px; /* 150% */
 overflow-x: hidden;
 overflow-y: auto;
 width: 100%;
}

a {
 text-decoration: none;
 color: var(--text-primary);
 cursor: pointer;
}

link {
 text-decoration: none;
 cursor: pointer;
}

p {
 margin-block: 0;
 margin-bottom: 18px;
}

h2 {
 /* headline-h2/pc */
 color: var(--text-primary);
 font-family: "Grtsk Tera";
 font-size: 24px;
 font-style: normal;
 font-weight: 600;
 line-height: 28px; /* 116.667% */
 margin-block: 0;
}

h3 {
 /* headline-h3/pc */
 color: var(--text-primary);
 font-family: "Grtsk Tera";
 font-size: 18px;
 font-style: normal;
 font-weight: 600;
 line-height: 24px; /* 133.333% */
 margin-block: 0;
}

.header {
 display: flex;
 padding: 34px 24px;
 justify-content: space-between;
 align-items: flex-start;
 align-self: stretch;
}

.header img {
 width: 172px;
}

.documentation_button {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 4px;
}

.documentation_button:hover svg path {
 stroke: var(--text-accent);
}

.documentation_button:hover .text_2 {
 color: var(--text-accent);
}

.main {
 /* первый экран */
 display: flex;
 /* width: 1280px; */
 padding: 0px 24px 60px 24px;
 flex-direction: column;
 align-items: flex-start;
 gap: 15px;
}

.text_2 {
 color: var(--text-tertiary);
 font-family: Manrope;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px; /* 142.857% */
}

.text_title {
 color: var(--text-primary);
 font-family: Manrope;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 24px; /* 150% */
}

.main_title {
 display: flex;
 padding: 12px 0px 20px 0px;
 align-items: flex-start;
 width: 100%;
 align-self: stretch;
 border-bottom: 1px solid var(--borders-primary);
}

/* .main_content {
 display: flex;
 align-items: flex-start;
 gap: 44px;
 align-self: stretch;
} */

.main_content {
 display: flex;
 justify-content: center;
 align-items: flex-start;
 gap: 44px;
 align-self: stretch;
}

/* картинка */
.screens {
 display: flex;
 justify-content: flex-end;
 align-items: flex-start;
 flex: 1 0 0;
 align-self: stretch;
}

.screens img {
 object-fit: cover;
 width: 100%;
}

.main_text {
 display: flex;
 padding-top: 20px;
 flex-direction: column;
 align-items: flex-start;
 gap: 28px;
 width: 395px;
}

.main_text_block {
 display: flex;
 /* width: calc(50vw - 12px); */
 width: 100%;
 padding: 0px 20px;
 flex-direction: column;
 align-items: flex-start;
 gap: 20px;
 align-self: stretch;
}

.main_text_blocks {
 display: flex;
}

.main_text_and_prices {
 display: flex;
 align-items: flex-start;
 gap: 56px;
}

/* ЦЕНЫ */

.prices {
 display: flex;
 max-width: 290px;
 flex-direction: column;
 align-items: flex-start;
 gap: 28px;
 flex: 1 0 0;
}

.price_card {
 display: flex;
 padding: 20px;
 flex-direction: column;
 align-items: center;
 gap: 20px;
 align-self: stretch;
 border-radius: 20px;
 background: var(--backgrounds-light);
}

.price_first_and_attention {
 flex-direction: column;
 display: flex;
 gap: 20px;
}

.price_card_title_and_text {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 16px;
 align-self: stretch;
}

.price_attention {
 display: flex;
 align-items: flex-start;
 gap: 4px;
 align-self: stretch;
}

/* КНОПКИ */

button {
 color: var(--text-w-100);
 display: flex;
 width: 100%;
 height: 56px;
 padding: 18px 32px;
 justify-content: center;
 align-items: center;
 gap: 10px;
 border: 0;
 border-radius: 6px;
 background: var(--text-primary);
 transition: 1s;
 cursor: pointer;
}

button:hover {
 background: var(--text-accent);
 transition: 0.1s;
}

button .text_title {
 color: var(--text-w-100);
}

button:hover .text_title {
 color: var(--text-w-100);
}

.button_2 {
 border: 1px solid var(--borders-primary);
 background: none;
}
.button_2:hover {
 background: var(--text-accent);
 transition: 0.1s;
 color: var(--text-accent);
}

.button_2 .text_title {
 color: var(--text-primary);
}

.full_width_button {
 width: 100%;
}

/* конец первого экрана */

/* детали */

.details {
 display: flex;
 padding: 0px 24px 40px 24px;
 flex-direction: column;
 align-self: stretch;
}

.tabs {
 display: flex;
 justify-content: flex-start;
 border-bottom: var(--borders-primary) solid 1px;
 gap: 24px;
 padding: 0;
}

.tab {
 cursor: pointer;
 color: var(--text-tertiary);

 /* headline-h3/pc */
 font-family: "Grtsk Tera";
 font-size: 18px;
 font-style: normal;
 font-weight: 600;
 line-height: 24px; /* 133.333% */
 transition: color 0.3s;
 padding-bottom: 20px;
 border-bottom: var(--text-accent) solid 0px;
 transition: 0.3s;
}
.tab:hover {
 color: var(--text-accent);
 border-bottom: var(--text-accent) solid 4px;
 transition: 0.3s;
}

.tab.active {
 color: var(--text-accent);
 border-bottom: var(--text-accent) solid 4px;
}

.tab_content {
 margin-top: 40px;
 transition: 0.3s;
 width: 395px;
}

/* подвал */

.footer {
 display: flex;
 padding: 49px 24px 24px 24px;
 flex-direction: column;
 align-items: flex-start;
 gap: 24px;
 background: var(--text-primary);
}

.footer_round_bg {
 background: var(--text-primary);
}
.footer_round {
 display: flex;
 width: 100%;
 height: 20px;
 background: var(--text-w-100);
 border-radius: 0 0 20px 20px;
}

@media (max-width: 1280px) {
 body {
  font-size: 14px;
  line-height: 20px; /* 142.857% */
 }
 h2 {
  font-size: 18px;
 }
 /* .documentation_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
 } */

 .header {
  align-items: center;
  padding: 20px 16px 24px 16px;
 }

 .header img {
  width: 36vw;
 }

 .main {
  padding: 12px 16px 20px 16px;
  gap: 11px;
 }

 .main_title {
  padding: 12px 0px 11px 0px;
 }

 .main_text_and_prices {
  flex-direction: column-reverse;
  width: 100%;
 }

 .main_content {
  flex-direction: column;
 }
 .prices {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
 }

 .price_card_container {
  display: flex;
  align-self: stretch;
 }

 .price_first_and_attention {
  gap: 16px;
  flex-direction: column;
  display: flex;
  align-self: stretch;
  width: 100%;
  max-width: 100%;
 }

 .price_second {
  width: 100%;
  max-width: 100%;
 }

 .prices .text_2 {
  max-width: 369px;
 }

 .prices h3 {
  max-width: 630px;
 }

 .price_card {
  width: 100%;
  max-width: 100%;
 }

 .main_text {
  width: 100%;
  gap: 16;
  flex-direction: row;
 }

 .main_text_blocks {
  display: flex;
  width: 100%;
 }

 .tab {
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  padding-bottom: 10px;
 }

 .tab_content {
  width: 100%;
 }

 .details {
  padding: 0px 18px 40px 18px;
 }

 .doc_popup_close_pc {
  display: flex;
 }
}

@media (max-width: 838px) {
 .main_text {
  flex-direction: column;
 }
}

@media (max-width: 560px) {
 .doc_popup_close_pc {
  display: none;
 }

 .prices {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
 }

 .documentation_button svg {
  display: none;
 }

 /* .tabs {
  flex-direction: column;
  gap: 16px;
 } */
 
}

.documentation_page {
 display: flex;
 align-items: center;
 position: fixed;
 padding-bottom: 336px;
 width: 100%;
 height: 100%;
 z-index: 2;
 background-color: rgba(0, 0, 0, 0.7);
}

.doc_popup {
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 100px;
 border-radius: 24px;
 background: var(--text-w-100);
 padding-bottom: 80px;
 /* padding-top: 56px; */
}

.home_page {
 position: relative;
 z-index: -1;
}

.doc_popup_text {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 20px;
 padding-bottom: 20px;
 align-self: stretch;
}

.doc_popup_text_and_button {
 display: flex;
 padding: 16px 16px 0;
 flex-direction: column;
 align-items: flex-end;
 gap: 4px;
 align-self: stretch;
}

.doc_popup_text_and_button {
 padding: 28px 32px 0px 32px;
}

.doc_popup {
 width: 558px;
 padding-bottom: 80px;
}

.documentation_page {
 justify-content: center;
}
